
/* Body */
body.theme-dark {
  background: #2d2e2e;
  color: #979899;
  /* Heading */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #a4a5a6;
  }

  /* Link Container */
  .t-link-container {
    color: inherit;
    display: block;
  }

  .t-link-container:hover,
  .t-link-container:focus,
  .t-link-container:active {
    color: inherit;
  }

  .t-link-container .t-link-container__item--blended {
    color: inherit;
  }

  /* Links */
  a,
  .t-link-container .t-link-container__item,
  .t-link-container:hover .t-link-container__item--blended {
    color: #e0a80d;
  }

  /* Hover Links */
  a:hover,
  a:focus,
  a:active,
  .t-link-container:hover .t-link-container__item {
    color: #fefaee;
  }

  /* Horizontal Rulers */
  hr,
  .t-border-color {
    border-color: #404242;
  }

  hr,
  .t-border-color-bg {
    background: #404242;
  }

  /* Primary Color */
  .t-primary-color,
  .t-primary-color-line {
    color: #e0a80d;
  }

  .t-primary-bg,
  .t-primary-color-line {
    background: #e0a80d;
  }

  /* Preloader */
  .t-preloader__spinner:not(:required):before {
    border-top-color: #e0a80d;
    border-bottom-color: #e0a80d;
  }

  /* Brand Colors */
  .t-title__first-word {
    color: #e0a80d;
  }

  .t-sub-title {
    color: #979899;
  }

  /* Brand Typography */
  .t-title {
    font-size: 4.8rem;
    line-height: 0.91;
  }

  .t-title__first-word {
    text-transform: uppercase;
    font-weight: 700;
  }

  .t-sub-title {
    font-weight: 300;
    font-size: 1.5rem;
    margin-left: .3rem;
  }

  /* Icons */
  .fas,
  .far,
  .fal,
  .fab {
    color: #575859;
  }

  /* Social Buttons */
  .t-social-button {
    background: #3f4040;
    border-color: #3f4040;
  }

  .t-social-button .fas,
  .t-social-button .far,
  .t-social-button .fal,
  .t-social-button .fab {
    color: #717273;
  }

  .t-social-button:hover {
    background: #323333;
  }

  .t-social-button:hover .fas,
  .t-social-button:hover .far,
  .t-social-button:hover .fal,
  .t-social-button:hover .fab {
    color: #e0a80d;
  }

  /* Image Overlay */
  .t-image-overlay {
    background: #2d2e2e;
  }

  .t-image-overlay * {
    color: #e4e5e5;
  }

  .t-image-overlay:hover img {
    opacity: 0.4;
  }

  .t-image-overlay__deco-line {
    color: #c0c1c1;
    background: #c0c1c1;
  }

  /* Transition */
  a,
  button,
  a .fa,
  .t-link-container .t-link-container__item,
  .t-link-container .t-link-container__item--blended {
    transition: all .15s linear;
  }

  /* Sections */
  .t-section__header {
    background: #282929;
  }

  .t-section__content,
  .t-section--header .t-section__header {
    background: #2d2e2e;
  }

  .t-section__content-border-color {
    border-color: #2d2e2e;
  }

  .t-timeline__point {
    box-shadow: 0 0 0 6px #2d2e2e;
  }

  /* Pills */
  .c-work__technology-pills {
    font-size: 0.8rem;
    .c-work__technology-pill {
      margin-top: 3px;
      margin-bottom: 3px;
      margin-right: 6px;
      border-radius: 3px;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: #3f4040;
      color: #F8BB10;
      display: inline-block;
    }
  }

  .c-skills-pills {
    margin-top: 10px;
    font-size: 0.9rem;
    .c-skills-pill {
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 6px;
      border-radius: 3px;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: #3f4040;
      color: #F8BB10;
      display: inline-block;
    }
  }

  @media (max-width: 1023px) {
    .t-section__header {
      border-top: 1px solid #404242;
      border-bottom: 1px solid #404242;
    }
    .t-section--header .t-section__header {
      border-top: none;
      border-bottom: none;
    }
  }

  @media (min-width: 1024px) {
    /* Odd Sections */
    .t-section,
    .t-section__header,
    .t-section--header .t-section__header {
      background: #2b2c2c;
    }
    .t-section__content {
      background: #2d2e2e;
    }
    /* Even Sections */
    .t-section:nth-child(even),
    .t-section:nth-child(even) .t-section__header {
      background: #292a2a;
    }
    .t-section:nth-child(even) .t-section__content {
      background: #2b2c2c;
    }
    .t-section:nth-child(even) .t-timeline__point {
      box-shadow: 0 0 0 6px #2b2c2c;
    }
  }
}
