.theme-light {
    .about {
        color: #727272;
        margin-bottom: 1em;
    }
}

.theme-dark {
    .about {
        color: #a4a5a6;
        margin-bottom: 1em;
    }
}

.responsibilities {
    margin-bottom: 0.5em;
}