@charset "UTF-8";

@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700,700italic,900,900italic&subset=latin,latin-ext);

@import "./assets/plugins/font-awesome/css/all.min.css";

@import "./assets/scss/normalize.scss";
@import "./assets/scss/global.scss";
@import "./assets/scss/theme.light.scss";
@import "./assets/scss/theme.dark.scss";
@import "./assets/scss/theme.switch.scss";
@import "./assets/scss/custom.scss";
